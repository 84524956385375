import axios from "axios";

const baseUrl = 'https://h5w1ohbj6f.execute-api.ap-southeast-1.amazonaws.com/develop';

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

//   Gán token vào header
axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      // const session = await Auth.currentSession();
      // config.headers.Authorization = `Bearer ${session.getAccessToken().getJwtToken()}`;
    } catch (error) {
      console.log(error);
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);



export default axiosInstance;
