import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TwitterIcon from "@/assets/images/icon/svg/social/twitter.svg";
import FacebookIcon from "@/assets/images/icon/svg/social/facebook.svg";
import InstagramIcon from "@/assets/images/icon/svg/social/instagram.svg";
import LinkedinIcon from "@/assets/images/icon/svg/social/linkedin.svg";
import "./Footer.css";
import { Link } from "react-router-dom";
import Social from "@/constants/social";
import MobileAppStore from "@/constants/mobile-store";
const Footer = () => {
  const footer = [
    {
      title: "Về FinHub",
      paths: [
        {
          label: "Slogan",
          path: "/about/slogan",
        },
        {
          label: "Giới thiệu",
          path: "/about/gioi-thieu",
        },
        {
          label: "Giá trị cốt lõi",
          path: "/about/gia-tri-cot-loi",
        },
        {
          label: "Đội ngũ quản lý",
          path: "/about/doi-ngu-quan-ly",
        },
        {
          label: "Sản phẩm",
          path: "/about/san-pham",
        },
        // {
        //   label: "Đối tác & đơn vị hỗ trợ",
        //   path: "/about/doi-tac-va-don-vi-ho-tro",
        // },
        {
          label: "Báo chí & Truyền thông",
          path: "/about/journalist-and-communication",
        },
        {
          label: "Cơ hội nghề nghiệp",
          path: "/about/co-hoi-nghe-nghiep",
        },
        
      ],
    },
    {
      title: "Sản phẩm",
      paths: [
        {
          label: "Chứng chỉ quỹ mở",
          path: "/product/chung-chi-quy-mo",
        },
        {
          label: "Công cụ",
          path: "/cong-cu",
        },
        {
          label: "Kiến thức",
          path: "/kien-thuc",
        },
        
        
      ],
    },
    {
      title: "Hỗ trợ",
      paths: [
        {
          label: "Câu hỏi thường gặp",
          path: "/support/cau-hoi-thuong-gap",
        },
        {
          label: "Điều khoản dịch vụ",
          path: "/support/dieu-khoan-dich-vu",
        },
      ],
    },
    {
      title: "Blog",
      paths: [
        {
          label: "Mới nhất",
          path: "/blog/latest",
        },
        {
          label: "Thông số tài chính",
          path: "/blog/Thông%20số%20tài%20chính?type=2",
        },
        {
          label: "Quản lý tài chính cá nhân",
          path: "/blog/Quản%20lý%20tài%20chính%20cá%20nhân?type=3",
        },
        {
          label: "Quản lý tài sản",
          path: "/blog/Quản%20lý%20tài%20sản?type=4",
        },
        {
          label: "Tin tức",
          path: "/blog/news",
        },
      ],
    },
  ];
  return (
    <footer className="text-light footer-container" style={{ color: "#fff" }}>
      <Container fluid>
        <Row>
          <Col xs={12} lg={3} className="footer-introduce">
            <img
              width={200}
              src={require("@/assets/images/picture/jpg/qr.jpg")}
              alt="QR Code"
            />
            <a href={MobileAppStore.ANDROID} target="_blank">
              <img
                src={
                  require("@/assets/images/icon/svg/google-play.svg").default
                }
                width={130}
                alt="Google Play"
              />
            </a>
            <a href={MobileAppStore.APPLE} target="_blank">
              <img
                src={require("@/assets/images/icon/svg/app-store.svg").default}
                width={130}
                alt="App Store"
              />
            </a>
          </Col>

          <Col xs={12} lg={9} className="footer-info">
            <Row>
              {footer.map((item) => {
                return (
                  <Col
                    key={item.title}
                    xs={12}
                    md={2}
                    className="footer-info-child"
                  >
                    <p className="footer-title">{item.title}</p>
                    <ul className="list-unstyled footer-ul">
                      {item.paths.map((pathItem) => {
                        return (
                          <li key={pathItem.path}>
                            <Link to={pathItem.path}>{pathItem.label}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                );
              })}

              <Col xs={12} md={3} className="footer-info-child">
                <p className="footer-title">Liên hệ</p>

                <ul className="list-unstyled footer-ul">
                  <li>
                    <img
                      width={30}
                      height={30}
                      src={
                        require("@/assets/images/icon/svg/ic_baseline-email.svg")
                          .default
                      }
                      alt="Email Icon"
                    />{" "}
                    contact@finhub.com.vn
                  </li>
                  <li>
                    <img
                      width={30}
                      height={30}
                      src={
                        require("@/assets/images/icon/svg/mdi_location.svg")
                          .default
                      }
                      alt="Phone Icon"
                    />{" "}
                    Trung tâm Đổi mới sáng tạo Quốc gia, tòa nhà NIC, ngõ 7 Tôn
                    Thất Thuyết, phường Dịch Vọng Hậu, quận Cầu Giấy, Hà Nội
                  </li>
                </ul>
                <div className="d-flex gap-4 footer-social">
                  <a href={Social.TWITTER} target="_blank">
                    <img
                      width={20}
                      height={20}
                      src={TwitterIcon}
                      alt="twitter Icon"
                    />
                  </a>
                  <a href={Social.FACEBOOK} target="_blank">
                    <img
                      width={20}
                      height={20}
                      src={FacebookIcon}
                      alt="facebook Icon"
                    />
                  </a>

                  <a href={Social.INSTAGRAM} target="_blank">
                    <img
                      width={20}
                      height={20}
                      src={InstagramIcon}
                      alt="instagram Icon"
                    />
                  </a>
                  <a href={Social.LINKEDIN} target="_blank">
                    <img
                      width={20}
                      height={20}
                      src={LinkedinIcon}
                      alt="linkedin Icon"
                    />
                  </a>

                  {/* Add other icons as needed */}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
