import "./CarouselSystem.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './CarouselSystem.module.css'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';


function CarouselSystem() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span className="' + className + '">' + (index + 1) + '</span>';
    },
  };

  return (
    <>
      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className={styles.carousel + ' mySwiper'}
      >
        <SwiperSlide><img src={require("@/assets/images/picture/jpg/system-finhub-slide-1.jpg")} /></SwiperSlide>
        <SwiperSlide><img src={require("@/assets/images/picture/jpg/system-finhub-slide-2.jpg")} /></SwiperSlide>
        <SwiperSlide><img src={require("@/assets/images/picture/jpg/system-finhub-slide-3.jpg")} /></SwiperSlide>
      </Swiper>
    </>
  );
}

export default CarouselSystem;
