import { useRef, useEffect, useState } from "react";
import styles from "./FrequentlyAskedQuestions.module.css"; // Import your CSS styles

function FrequentlyAskedQuestions() {
  const firstItemRef = useRef(null);
  const secondItemRef = useRef(null);
  const thirdItemRef = useRef(null);
  const fourItemRef = useRef(null);
  const fiveItemRef = useRef(null);
  const [firstItem, setFirstItem] = useState(false);
  const [secondItem, setSecondItem] = useState(false);
  const [thirdItem, setThirdItem] = useState(false);
  const [fourItem, setFourItem] = useState(false);
  const [fiveItem, setFiveItem] = useState(false);
  useEffect(() => {
    const firstElement = firstItemRef.current;
    const secondElement = secondItemRef.current;
    const thirdElement = thirdItemRef.current;
    const fourElement = fourItemRef.current;
    // const fiveElement = fiveItemRef.current;

    // Define the observer callback function
    const handleAttributeChange = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "attributes") {
          const attributeName = mutation.attributeName;
          const attributeValue1 = firstElement.getAttribute(attributeName);
          const attributeValue2 = secondElement.getAttribute(attributeName);
          const attributeValue3 = thirdElement.getAttribute(attributeName);
          const attributeValue4 = fourElement.getAttribute(attributeName);
          // const attributeValue5 = fiveElement.getAttribute(attributeName);
          setFirstItem(attributeValue1 === "true" ? true : false);
          setSecondItem(attributeValue2 === "true" ? true : false);
          setThirdItem(attributeValue3 === "true" ? true : false);
          setFourItem(attributeValue4 === "true" ? true : false);
          // setFiveItem(attributeValue5 === "true" ? true : false);
        }
      });
    };

    // Create an observer instance
    const observer = new MutationObserver(handleAttributeChange);

    // Start observing the 'data-custom-attribute' attribute
    observer.observe(firstElement, {
      attributes: true,
      attributeFilter: ["aria-expanded"],
    });
    observer.observe(secondElement, {
      attributes: true,
      attributeFilter: ["aria-expanded"],
    });

    observer.observe(thirdElement, {
      attributes: true,
      attributeFilter: ["aria-expanded"],
    });

    observer.observe(fourElement, {
      attributes: true,
      attributeFilter: ["aria-expanded"],
    });

    // observer.observe(fiveElement, {
    //   attributes: true,
    //   attributeFilter: ["aria-expanded"],
    // });

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div className={styles.root_container}>
      <div className={styles.header}>
        <p className={styles.title}>
          <span className="primary-color">Tìm hiểu</span> FinHub
        </p>
        <p>
          Nếu bạn không chắc liệu FinHub có phù hợp với mình hay không, đừng lo
          lắng. Chúng tôi sẽ giải thích mọi thứ bạn muốn biết.
        </p>
      </div>

      <div
        className={
          styles.title + " primary-color d-flex gap-2 align-items-center"
        }
      >
        <img
          className={styles.icon}
          src={require("@/assets/images/icon/svg/faq-icon.svg").default}
        />{" "}
        <span>Tất cả các câu hỏi</span>
      </div>

      <div className="container-fluid p-0 d-grid gap-4">
        {/* 1 */}
        <div className="row">
          <div className={styles.collapse_container}>
            <div
              ref={firstItemRef}
              className={
                styles.collapse_header + " " + (firstItem ? styles.active : "")
              }
              data-bs-toggle="collapse"
              data-bs-target="#collapseIFirst"
              aria-expanded="false"
              aria-controls="collapseIFirst"
            >
              <div className="d-flex align-items-center gap-5">
                <div className={styles.title_toggle}>
                Làm thế nào để đầu tư trên FinHub?
                </div>
              </div>

              <div>
                {firstItem ? (
                  <img
                    className={styles.icon}
                    src={
                      require("@/assets/images/icon/svg/ep_arrow-up.svg")
                        .default
                    }
                  />
                ) : (
                  <img
                    className={styles.icon}
                    src={require("@/assets/images/icon/svg/plus.svg").default}
                  />
                )}
              </div>
            </div>
            <div className={"collapse"} id="collapseIFirst">
              <div className={styles.collapse_content}>
              FinHub là nền tảng cung cấp kiến thức, phân tích và đánh giá hiệu suất chứng chỉ Quỹ mở nhằm giúp người dùng đưa ra quyết định đầu tư phù hợp với bản thân. Nếu bạn là một nhà đầu tư, bạn có thể vào trực tiếp trang web của các Quỹ đầu tư để thực hiện giao dịch.
              </div>
            </div>
          </div>
        </div>
        {/* 2 */}
        <div className="row">
          <div className={styles.collapse_container}>
            <div
              ref={secondItemRef}
              className={
                styles.collapse_header + " " + (secondItem ? styles.active : "")
              }
              data-bs-toggle="collapse"
              data-bs-target="#collapseSecond"
              aria-expanded="false"
              aria-controls="collapseSecond"
            >
              <div className="">
                <div className={styles.title_toggle}>
                Ứng dụng FinHub có mất phí không?
                </div>
              </div>

              <div>
                {secondItem ? (
                  <img
                    className={styles.icon}
                    src={
                      require("@/assets/images/icon/svg/ep_arrow-up.svg")
                        .default
                    }
                  />
                ) : (
                  <img
                    className={styles.icon}
                    src={require("@/assets/images/icon/svg/plus.svg").default}
                  />
                )}
              </div>
            </div>
            <div className={"collapse"} id="collapseSecond">
              <div className={styles.collapse_content}>
              Người dùng có thể đăng ký tài khoản, sử dụng dịch vụ trên ứng dụng FinHub hoàn toàn miễn phí.
              </div>
            </div>
          </div>
        </div>
        {/* 3 */}
        <div className="row">
          <div className={styles.collapse_container}>
            <div
              ref={thirdItemRef}
              className={
                styles.collapse_header + " " + (thirdItem ? styles.active : "")
              }
              data-bs-toggle="collapse"
              data-bs-target="#collapsethird"
              aria-expanded="false"
              aria-controls="collapsethird"
            >
              <div className="">
                <div className={styles.title_toggle}>
                Ưu điểm của FinHub so với ứng dụng khác là gì?
                </div>
              </div>

              <div>
                {thirdItem ? (
                  <img
                    className={styles.icon}
                    src={
                      require("@/assets/images/icon/svg/ep_arrow-up.svg")
                        .default
                    }
                  />
                ) : (
                  <img
                    className={styles.icon}
                    src={require("@/assets/images/icon/svg/plus.svg").default}
                  />
                )}
              </div>
            </div>
            <div className={"collapse"} id="collapsethird">
              <div className={styles.collapse_content}>
              Tính năng Robo Advisor trên ứng dụng FinHub dựa trên lý thuyết danh mục đầu tư hiện đại, giúp các nhà đầu tư xây dựng danh mục tối ưu phù hợp với hồ sơ rủi ro của họ. FinHub cũng cung cấp cho các nhà đầu tư những nội dung giáo dục được cá nhân hóa giúp họ ngày càng chuyên nghiệp hơn trong đầu tư tài chính.
              </div>
            </div>
          </div>
        </div>

        {/* 4 */}
        <div className="row">
          <div className={styles.collapse_container}>
            <div
              ref={fourItemRef}
              className={
                styles.collapse_header + " " + (fourItem ? styles.active : "")
              }
              data-bs-toggle="collapse"
              data-bs-target="#collapsefour"
              aria-expanded="false"
              aria-controls="collapsefour"
            >
              <div className="">
                <div className={styles.title_toggle}>
                Tôi có thể cập nhật kiến thức tài chính gì trên FinHub?
                </div>
              </div>

              <div>
                {fourItem ? (
                  <img
                    className={styles.icon}
                    src={
                      require("@/assets/images/icon/svg/ep_arrow-up.svg")
                        .default
                    }
                  />
                ) : (
                  <img
                    className={styles.icon}
                    src={require("@/assets/images/icon/svg/plus.svg").default}
                  />
                )}
              </div>
            </div>
            <div className={"collapse"} id="collapsefour">
              <div className={styles.collapse_content}>
              Tính năng Blog bao gồm các kiến thức về tài chính cá nhân, quản lý tài sản, đầu tư, thị trường, chính sách và cập nhật tin tức trong nước lẫn quốc tế giúp người đọc nắm bắt thông tin kịp thời, nâng cao hiểu biết về tài chính, và ra quyết định đầu tư thông minh hơn.
              </div>
            </div>
          </div>
        </div>
        {/* 5 */}
        {/* <div className="row">
          <div className={styles.collapse_container}>
            <div
              ref={fiveItemRef}
              className={
                styles.collapse_header + " " + (fiveItem ? styles.active : "")
              }
              data-bs-toggle="collapse"
              data-bs-target="#collapsefive"
              aria-expanded="false"
              aria-controls="collapsefive"
            >
              <div className="">
                <div className={styles.title_toggle}>
                  Số tiền đầu tư tối thiểu là bao nhiêu?
                </div>
              </div>

              <div>
                {fiveItem ? (
                  <img
                    className={styles.icon}
                    src={
                      require("@/assets/images/icon/svg/ep_arrow-up.svg")
                        .default
                    }
                  />
                ) : (
                  <img
                    className={styles.icon}
                    src={require("@/assets/images/icon/svg/plus.svg").default}
                  />
                )}
              </div>
            </div>
            <div className={"collapse"} id="collapsefive">
              <div className={styles.collapse_content}>
                Chỉ từ 100,000 VND, nhà đầu tư có thể sở hữu trực tiếp chứng chỉ
                quỹ của các quỹ đầu tư hàng đầu.
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default FrequentlyAskedQuestions;
